import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import iconImg from "../../images/Info.png";
import whatsImg from "../../images/whatsapp-removebg-preview.png";
import configImg from "../../images/configuration.png";
import "./Compalin.css";
import fbImg from "../../images/PngItem_200308.png";
import logo from "../../images/bet365_logo.png";

const Compalin = () => {
  const [complain, setComplain] = useState([]);
  const { agentId } = useParams();

  useEffect(() => {
    fetch("/OnlineMasterComplain.JSON")
      .then((res) => res.json())
      .then((data) => setComplain(data));
  }, []);

  const findData = complain?.filter((ele) => ele?.id == agentId);

  return (
    <div className="main-section" id="home-page">
      <div className="content-section">
        <div className="">
          <div className="logo-section">
            <img src={logo} alt="" />
          </div>
          {/* <h3>সততাই আমাদের একমাএ পন্থা</h3> */}
          {/* <div className="paymet-option">
                        <img className='img-fluid payment-img' src={paymentImg} alt="" />
                        <div className="bank-logo">
                            <img className='city-Bank-Logo' src={cityBankLogo} alt="" />
                            <img className='dutch-logo' src={dutchBankLogo} alt="" />
                        </div>
                    </div> */}
        </div>
        {/* navigation bar  */}
        <div className="navigation-bar">
          <ul>
            <li>
              <Link className="nav-menu" to="/home">
                <i className="fa-solid fa-house"></i>
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/customarservice">
                <i className="fa-duotone fa-question question-icon"></i> SERVICE
              </Link>
            </li>

            <li>
              <Link className="nav-menu" to="/admin">
                <img className="configImg" src={configImg} alt="" /> ADMIN
              </Link>{" "}
              {/*<i className="fa-solid fa-user"></i>// */}
            </li>
            <li>
              <Link className="nav-menu" to="/SubAdmin">
                <img className="configImg" src={configImg} alt="" />
                SUB ADMIN
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/superagent">
                <img className="configImg" src={configImg} alt="" /> SUPER
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/onlineagent">
                <img className="configImg" src={configImg} alt="" />
                MASTER
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/OLDNEW">
                <img className="configImg" src={configImg} alt="" />
                OLD/NEW
              </Link>
            </li>
          </ul>
        </div>

        <div className="account-info">
          <div className="quick-master-title">
            <img src={iconImg} alt="" />
            <h1> {findData[0]?.name} - সুপার এজেন্ট </h1>
          </div>
          <div className="account-info-content" id="agent-list-icon-number">
            <div className="mobile-app-link">
              <h4>Mobile App Link</h4>
              <div className="socail-media-icons">
                {/* <a href={findData[0]?.facebook}>
                                    <img src={fbImg} id='facebook-img' className='img-fluid' alt="facebook" />
                                </a> */}
                <a href={findData[0]?.whatsapp}>
                  <img
                    className="img-fluid"
                    id="whatsapp-img"
                    src={whatsImg}
                    alt="whatsapp"
                  />
                </a>
                {/* <a href={findData[0]?.messenger}>
                  <img
                    className="img-fluid"
                    id="messsenger-img"
                    src={messngImg}
                    alt="messenger"
                  />
                </a> */}
              </div>
            </div>
            <div className="phone-number-secion">
              <h4>Phone Number</h4>
              <p className="phone-number">{findData[0]?.number}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compalin;
