import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Admin from "./Component/Admin/Admin";
import Compalin from "./Component/Compalin/Compalin";
import CustomarService from "./Component/Home/CustomarService/CustomarService";
import Home from "./Component/Home/Home";
import OnlineAgent from "./Component/OnlineAgent/OnlineAgent";
import SuperAgent from "./Component/SuperAgent/SuperAgent";
import SuperComplain from "./Component/SuperComplain/SuperComplain";
import SubAdmin from "./Component/Admin/SubAdmin";
import SuperAdmin from "./Component/Admin/SuperAdmin";
import OldNew from "./Component/oldNew/OldNew";
import ExtraPage from "./Sheard/Header/ExtraPage";
import SuperExtraPage from "./Sheard/Header/SuperExtraPage";
import MasterExtraPage from "./Sheard/Header/MasterExtraPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home></Home>} />
          <Route path="/home" element={<Home></Home>} />
          <Route
            path="/customarservice"
            element={<CustomarService></CustomarService>}
          />
          <Route path="/superAdmin" element={<SuperAdmin></SuperAdmin>} />
          <Route path="/admin" element={<Admin></Admin>} />
          <Route path="/SubAdmin" element={<SubAdmin></SubAdmin>} />
          <Route path="/superagent" element={<SuperAgent></SuperAgent>} />
          <Route path="/onlineagent" element={<OnlineAgent></OnlineAgent>} />
          <Route path="/OLDNEW" element={<OldNew></OldNew>} />
          <Route path="/extrapage" element={<ExtraPage></ExtraPage>} />
          <Route
            path="/superextrapage"
            element={<SuperExtraPage></SuperExtraPage>}
          />
          <Route path="/masterextrapage" element={<MasterExtraPage />} />
          <Route
            path="/complain/:agentId"
            element={<Compalin></Compalin>}
          ></Route>
          <Route path="/superComplain/:superId" element={<SuperComplain />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
