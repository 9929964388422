import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/bet365_logos.png";
import configImg from "../../images/configuration.png";

const SuperExtraPage = () => {
  return (
    <div className="main-section" id="home-page">
      <div className="content-section">
        <div className="">
          <div className="logo-section">
            <a href="https://bets3.in">
              <img src={logo} alt="" />
            </a>
          </div>
        </div>
        {/* navigation bar  */}
        <div className="navigation-bar">
          <ul>
            <li>
              <Link className="nav-menu" to="/home">
                <i className="fa-solid fa-house"></i>
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/customarservice">
                <i className="fa-duotone fa-question question-icon"></i> SERVICE
              </Link>
            </li>

            <li>
              <Link className="nav-menu" to="/admin">
                <img className="configImg" src={configImg} alt="" /> ADMIN
              </Link>{" "}
              {/*<i className="fa-solid fa-user"></i>// */}
            </li>
            {/* <li>
              <Link className="nav-menu" to="/SubAdmin">
                <img className="configImg" src={configImg} alt="" />
                SUB ADMIN
              </Link>
            </li> */}
            <li>
              <Link className="nav-menu" to="/superagent">
                <img className="configImg" src={configImg} alt="" /> SUPER
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/onlineagent">
                <img className="configImg" src={configImg} alt="" />
                MASTER
              </Link>
            </li>
            {/* <li>
              <Link className="nav-menu" to="/OLDNEW">
                <img className="configImg" src={configImg} alt="" />
                OLD/NEW
              </Link>
            </li> */}
          </ul>
        </div>

        <section className="section_bg">
          <div>
            <h1> সুপার এজেন্ট হবার নিয়ম </h1>
            <div>
              <p>**********</p>
              <p>পয়েন্ট কেনা বেচা</p>
              <p>**********</p>
              <p>** সুপার এজেন্ট বেট৩৬৫ থেকে পয়েন্ট কিনবে ৫৫ টাকা করে </p>
              <p>
                ** সুপার এজেন্ট মাষ্টার বা লোকালের কাছে বিক্রি করবে ৭০ টাকা করে
              </p>
              <p>** এইখানে সুপার এজেন্টের লাভ থাকবে ১৫%</p>
              <p>**********</p>
              <p>আবারঃ </p>
              <p>**********</p>
              <p>
                ** সুপার এজেন্ট মাস্টার বা লোকালের কাছ থেকে পয়েন্ট কিনবে ৭০
                টাকা করে{" "}
              </p>
              <p>** সুপার এজেন্ট বেট৩৬৫ কাছে বিক্রি করবে ৫৫ টাকা করে</p>
              <p>** এখানে সুপার এজেন্টের লস হবে ১৫%</p>
              <p>**********</p>
              {/* <img className="agent_img" src={agentImg} alt="" /> */}
              <p>
                ** তাহলে প্রশ্ন আসতে পারে সুপার এজেন্ট হবার লাভ কোথায়। সুপার
                এজেন্টদের লাভ হচ্ছে তার কাছে যখন ১০ থেকে ২০ জন রানিং মাস্টার বা
                লোকাল এজেন্ট থাকবে। তখন এই ১০ থেকে ২০ জনকে মিলিয়ে গড়ে পয়েন্ট
                লসই থাকবে।
              </p>
              <p>**********</p>
              <p>শর্তসমূহঃ </p>
              <p>**********</p>
              <p>
                ** সুপার এজেন্টকে ৭৫ হাজার টাকার পয়েন্ট কিনে এজেন্ট শুরু করতে
                হবে।
              </p>
              <p>
                ** এজেন্ট ব্যালেন্স বাদ দিয়ে সুপারের কাছে সবসময় ১০০০ পয়েন্ট
                এর ব্যালেন্স থাকতে হবে
              </p>
              <p>
                ** সুপার ব্যালেন্স যদি ২০০০ পয়েন্ট হয়ে যায় তাহলে সুপার ৫০০
                পয়েন্ট বেট৩৬৫ কাছে বিক্রি করতে পারবে{" "}
              </p>
              <p>** সুপারের কাছে সর্বনিম্ন ৫ জন একটিভ এজেন্ট থাকতে হবে </p>
              <p>
                ** সুপার মাষ্টার বা লোকালের সাথে প্রতি পয়েন্ট কেনাবেচা করবে ৭০
                টাকা করে{" "}
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SuperExtraPage;
