import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../OnlineAgent/OnlineAgent.css";
import whatsImg from "../../images/ws.png";
import configImg from "../../images/configuration.png";
import logo from "../../images/bet365_logo.png";
import fbImg from "../../images/PngItem_200308.png";

const OldNew = () => {
  // state declear
  const [onlineAgent, setOnlineAgent] = useState([]);
  const [displayAgent, setDisplayAgent] = useState([]);
  const [search, setSearch] = useState("");
  // data load on fake data
  useEffect(() => {
    fetch("./NEWOLDNUMBER.JSON")
      .then((res) => res.json())
      .then((data) => {
        setOnlineAgent(data);
        setDisplayAgent(data);
      });
  }, []);
  // input handle onchange
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  // handle search bar
  const handleSearch = (e) => {
    e.preventDefault();

    const filterValue = onlineAgent.filter(
      (onlineAg) =>
        onlineAg.id == search ||
        onlineAg.name.toLowerCase().includes(search.toLowerCase())
    );
    setDisplayAgent(filterValue);
  };

  return (
    <div>
      <div className="main-section" id="online__agent">
        <div className="content-section">
          {/* header section  */}
          <div className="">
            <div className="logo-section">
              <a href="https://bets3.in">
                <img src={logo} alt="" />
              </a>
            </div>
            {/* <h3>সততাই আমাদের একমাএ পন্থা</h3> */}
            {/* <div className="paymet-option">
                        <img className='img-fluid payment-img' src={paymentImg} alt="" />
                        <div className="bank-logo">
                            <img className='city-Bank-Logo' src={cityBankLogo} alt="" />
                            <img className='dutch-logo' src={dutchBankLogo} alt="" />
                        </div>
                    </div> */}
          </div>
          {/* navigation bar  */}
          <div className="navigation-bar">
            <ul>
              <li>
                <Link className="nav-menu" to="/home">
                  <i className="fa-solid fa-house"></i>
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/customarservice">
                  <i className="fa-duotone fa-question question-icon"></i>{" "}
                  SERVICE
                </Link>
              </li>

              <li>
                <Link className="nav-menu" to="/admin">
                  <img className="configImg" src={configImg} alt="" /> ADMIN
                </Link>{" "}
                {/*<i className="fa-solid fa-user"></i>// */}
              </li>
              {/* <li>
                <Link className="nav-menu" to="/SubAdmin">
                  <img className="configImg" src={configImg} alt="" />
                  SUB ADMIN
                </Link>
              </li> */}
              <li>
                <Link className="nav-menu" to="/superagent">
                  <img className="configImg" src={configImg} alt="" /> SUPER
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/onlineagent">
                  <img className="configImg" src={configImg} alt="" />
                  MASTER
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/OLDNEW">
                  <img className="configImg" src={configImg} alt="" />
                  OLD/NEW
                </Link>
              </li>
            </ul>
          </div>

          {/* marquee the paragarap    */}
          {/* <marquee className='marquee__list'>
                        <strong>Welcome To <a className='marquee__link' href="https://www.baaji365.live/#/"> Baaji365.Live </a>
                            Enjoy Beeting In Match Odds, Fancy & Live Casino
                        </strong>
                    </marquee> */}

          {/* search bar  */}
          {/* <form onSubmit={handleSearch} className="search-agent">
            <input
              type="text"
              placeholder="Search agent Id..."
              onChange={handleChange}
              value={search}
            />
            <button type="submit">
              <i className="fa-solid fa-magnifying-glass search-icon"></i>
            </button>
          </form> */}

          <div className="customar-service-list">
            <h4>BET365 OLD/NEW PHONE NUMBER</h4>
          </div>
          <div className="header_line_content">
            <p>
              হোয়াটসাপ ব্যাতিত অন্য কোন এপ এর মাধ্যমে যোগাযোগ বা লেনদেন করা যাবে
              না এবং করলে তা গ্রহনযোগ্য হবে না
            </p>
          </div>
          <div className="header_line_content">
            <p>
              এই খানে এজেন্ট দের পুরাতন এবং নতুন নাম্বার গুলো দেয়া আছে - একদম
              ডান দিকে নতুন হোয়াটসাপ নাম্বার। কোনভাবেই পুরাতন হোয়াটসাপ নাম্বার এ
              আর যোগাযোগ এবং লেনদেন করবেন না।
            </p>
          </div>
          {/* online agent table  */}
          <div className="customar-service-tabel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">TYPE</th>
                  <th scope="col">ID</th>
                  {/* <th scope="col">ID NO</th> */}
                  {/* <th scope="col">7* RATING</th> */}
                  <th scope="col">OLD PHONE NUMBER</th>
                  <th scope="col">SOCIAL MEDIA</th>
                  <th scope="col">NEW PHONE NUMBER</th>
                  {/* <th scope="col">SUPER</th> */}
                </tr>
              </thead>
              <tbody>
                {/* style={{ backgroundColor: index % 2 === 0 ? "#fff" : "#c5c8fd" }} */}
                {!displayAgent ? (
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  displayAgent.map((online, index) => (
                    <>
                      <tr className="onlineAgent-table" key={online.id}>
                        <td>{online.type}</td>
                        <td> ID : {online.id}</td>
                        <td className="oldNumber">{online.oldnumber}</td>
                        {/* <td>
                          {online.id}{" "}
                          <span className="online-name">{online.Lname}</span>
                        </td> */}
                        {/* <td>
                          <ul className="tabel-rating">
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                          </ul>
                        </td> */}
                        <td>
                          <div className="socail-icon">
                            {/* <a href={online.facebook}>
                              <img
                                src={fbImg}
                                id="fb-icon"
                                className="img-fluid icon-img"
                                alt="facebook"
                              />
                            </a> */}
                            {/* <a href={online.messenger}>
                              <img
                                id="agent_linkm"
                                src={messengerImg}
                                className="img-fluid icon-img"
                                alt="Whatsapp"
                              />
                            </a> */}

                            <a href={online.whatsapp}>
                              <img
                                id="agent_link"
                                src={whatsImg}
                                className="img-fluid icon-img"
                                alt="Whatsapp"
                              />
                            </a>
                          </div>
                        </td>
                        <td className="new_number">{online.number}</td>
                        {/* <td>
                          <Link
                            to={`/complain/${online.id}`}
                            className="complain-btn"
                          >
                            COMPLAIN
                          </Link>
                        
                        </td> */}
                      </tr>
                      {/* <tr className="customarAgent">
                        <td colSpan={7}>
                          <p className="tabel-details">
                            এজেন্ট এর সাথে হোয়াটসঅ্যাপ ও মেসেঞ্জার ব্যতিত অন্য
                            কোনো মাধ্যমে যোগাযোগ করা যাবে না এবং যোগাযোগ করলে তা
                            গ্রহনযোগ্য হবে না ।
                          </p>
                        </td>
                      </tr> */}
                    </>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OldNew;
