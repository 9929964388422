import React, { useState } from "react";
import { Link } from "react-router-dom";
import whatsImg from "../../images/ws.png";
import configImg from "../../images/configuration.png";
import "./Admin.css";
import logo from "../../images/bet365_logos.png";
import fbImg from "../../images/PngItem_200308.png";
import { useEffect } from "react";

const Admin = () => {
  const [admin, setAdmin] = useState([]);

  useEffect(() => {
    fetch("./Admin.JSON")
      .then((res) => res.json())
      .then((data) => setAdmin(data));
  }, []);

  return (
    <div>
      <div className="main-section">
        <div className="content-section">
          <div className="">
            <div className="logo-section">
              <a href="https://bets3.in">
                <img src={logo} alt="" />
              </a>
            </div>
            {/* <h3>সততাই আমাদের একমাএ পন্থা</h3> */}
            {/* <div className="paymet-option">
                        <img className='img-fluid payment-img' src={paymentImg} alt="" />
                        <div className="bank-logo">
                            <img className='city-Bank-Logo' src={cityBankLogo} alt="" />
                            <img className='dutch-logo' src={dutchBankLogo} alt="" />
                        </div>
                    </div> */}
          </div>
          <div className="navigation-bar">
            <ul>
              <li>
                <Link className="nav-menu" to="/home">
                  <i className="fa-solid fa-house"></i>
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/customarservice">
                  <i className="fa-duotone fa-question question-icon"></i>{" "}
                  SERVICE
                </Link>
              </li>

              <li>
                <Link className="nav-menu" to="/admin">
                  <img className="configImg" src={configImg} alt="" /> ADMIN
                </Link>{" "}
                {/*<i className="fa-solid fa-user"></i>// */}
              </li>
              {/* <li>
                <Link className="nav-menu" to="/SubAdmin">
                  <img className="configImg" src={configImg} alt="" />
                  SUB ADMIN
                </Link>
              </li> */}
              <li>
                <Link className="nav-menu" to="/superagent">
                  <img className="configImg" src={configImg} alt="" /> SUPER
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/onlineagent">
                  <img className="configImg" src={configImg} alt="" />
                  MASTER
                </Link>
              </li>
              {/* <li>
                <Link className="nav-menu" to="/OLDNEW">
                  <img className="configImg" src={configImg} alt="" />
                  OLD/NEW
                </Link>
              </li> */}
            </ul>
          </div>

          {/* marquee the paragarap    */}
          {/* <marquee className='marquee__list'>
                        <strong>Welcome To <a className='marquee__link' href="https://www.baaji365.live/#/"> Baaji365.Live </a>
                            Enjoy Beeting In Match Odds, Fancy & Live Casino
                        </strong>
                    </marquee> */}

          <div className="customar-service-list">
            <h4>BET365 ADMIN LIST</h4>
          </div>
          <div className="header_line_content">
            <p>
              হোয়াটসাপ ব্যাতিত অন্য কোন এপ এর মাধ্যমে যোগাযোগ বা লেনদেন করা যাবে
              না এবং করলে তা গ্রহনযোগ্য হবে না
            </p>
          </div>
          <div className="customar-service-tabel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">SL NO</th>
                  <th scope="col">TYPE</th>
                  <th scope="col">NAME</th>
                  <th scope="col">SOCIAL MEDIA</th>
                  <th scope="col">PHONE NUMBER</th>
                </tr>
              </thead>
              <tbody>
                {admin.map((admin) => {
                  return (
                    <>
                      <tr className="admin-tabel" key={admin.id}>
                        <td>{admin.id}</td>
                        <td>{admin.type}</td>
                        <td>{admin.name}</td>
                        <td>
                          <div className="socail-icon">
                            <a href="https://www.facebook.com/Baaji365.SuperAgent.01">
                              <img
                                src={fbImg}
                                id="fb-icon"
                                className="img-fluid icon-img"
                                alt="facebook"
                              />
                            </a>

                            {/* <a href={admin.messenger}>
                              <img
                                id="agent_linkm"
                                src={messengerImg}
                                className="img-fluid icon-img"
                                alt="Whatsapp"
                              />
                            </a> */}

                            <a href={admin.whatsapp}>
                              <img
                                id="agent_link"
                                src={whatsImg}
                                className="img-fluid icon-img"
                                alt="Whatsapp"
                              />
                            </a>
                          </div>
                        </td>
                        <td className="new_number">{admin.number}</td>
                      </tr>
                    </>
                  );
                })}
                {/* <tr className="customarAgent">
                  <td colSpan={4}>
                    <p className="tabel-details">
                      এডমিন এর সাথে হোয়াটসঅ্যাপ ও মেসেঞ্জার ব্যতিত অন্য কোনো
                      মাধ্যমে যোগাযোগ করা যাবে না এবং যোগাযোগ করলে তা গ্রহনযোগ্য
                      হবে না ।
                    </p>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          {/* <div className="tabel-description">
                        <p>* এজেন্ট ও বেট স্বম্পর্কিত কোন অভিযোগ এর জন্য "Admin" সাথে যোগাযোগ করার জন্য অনুরোধ করা হল।</p>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Admin;
