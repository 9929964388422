import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./SuperAgent.css";
import whatsImg from "../../images/ws.png";
import configImg from "../../images/configuration.png";
import logo from "../../images/bet365_logos.png";
import fbImg from "../../images/PngItem_200308.png";

const SuperAgent = () => {
  const [superAgent, setSuperAgent] = useState([]);
  const [displayAgent, setDisplayAgent] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetch("./SuperAgentList.JSON")
      .then((res) => res.json())
      .then((data) => {
        setSuperAgent(data);
        setDisplayAgent(data);
      });
  }, []);

  // input handle onchange
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  // handle search bar
  const handleSearch = (e) => {
    e.preventDefault();
    if (search === " ") {
      console.log("something");
    } else {
      const filterValue = superAgent.filter(
        (onlineAg) => onlineAg.id == search
        // onlineAg.name.toLowerCase().includes(search.toLowerCase())
      );
      setDisplayAgent(filterValue);
    }
  };

  return (
    <div>
      <div className="main-section">
        <div className="content-section">
          <div className="">
            <div className="logo-section">
              <a href="https://bets3.in">
                <img src={logo} alt="" />
              </a>
            </div>
            {/* <h3>সততাই আমাদের একমাএ পন্থা</h3> */}
            {/* <div className="paymet-option">
                        <img className='img-fluid payment-img' src={paymentImg} alt="" />
                        <div className="bank-logo">
                            <img className='city-Bank-Logo' src={cityBankLogo} alt="" />
                            <img className='dutch-logo' src={dutchBankLogo} alt="" />
                        </div>
                    </div> */}
          </div>
          <div className="navigation-bar">
            <ul>
              <li>
                <Link className="nav-menu" to="/home">
                  <i className="fa-solid fa-house"></i>
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/customarservice">
                  <i className="fa-duotone fa-question question-icon"></i>{" "}
                  SERVICE
                </Link>
              </li>

              <li>
                <Link className="nav-menu" to="/admin">
                  <img className="configImg" src={configImg} alt="" /> ADMIN
                </Link>{" "}
                {/*<i className="fa-solid fa-user"></i>// */}
              </li>
              {/* <li>
                <Link className="nav-menu" to="/SubAdmin">
                  <img className="configImg" src={configImg} alt="" />
                  SUB ADMIN
                </Link>
              </li> */}
              <li>
                <Link className="nav-menu" to="/superagent">
                  <img className="configImg" src={configImg} alt="" /> SUPER
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/onlineagent">
                  <img className="configImg" src={configImg} alt="" />
                  MASTER
                </Link>
              </li>
              {/* <li>
                <Link className="nav-menu" to="/OLDNEW">
                  <img className="configImg" src={configImg} alt="" />
                  OLD/NEW
                </Link>
              </li> */}
            </ul>
          </div>
          {/* marquee the paragarap    */}
          {/* <marquee className='marquee__list'>
                        <strong>Welcome To <a className='marquee__link' href="https://www.baaji365.live/#/"> Baaji365.Live </a>
                            Enjoy Beeting In Match Odds, Fancy & Live Casino
                        </strong>
                    </marquee> */}

          {/* search bar  */}
          <form onSubmit={handleSearch} className="search-agent">
            <input
              type="text"
              placeholder="Search agent Id..."
              onChange={handleChange}
              value={search}
            />
            <button type="submit">
              <i className="fa-solid fa-magnifying-glass search-icon"></i>
            </button>
          </form>
          <div className="customar-service-list">
            <h4>BET365 SUPER AGENT LIST</h4>
          </div>
          <div className="header_line_content">
            <p>
              হোয়াটসাপ ও ফেসবুক ব্যাতিত অন্য কোন এপ এর মাধ্যমে যোগাযোগ বা লেনদেন
              করা যাবে না এবং করলে তা গ্রহনযোগ্য হবে না
            </p>
          </div>
          <div className="header_line_link">
            <a href="/superextrapage">
              সুপার এজেন্ট কিভাবে হতে পারবেন তা জানার জন্য এই লিঙ্ক এ ক্লিক করুন
            </a>
          </div>
          <div className="customar-service-tabel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">SL NO</th>
                  <th scope="col">TYPE</th>
                  <th scope="col">NAME</th>
                  <th scope="col">SOCIAL MEDIA</th>
                  <th scope="col">PHONE NUMBER</th>
                  <th scope="col">ADMIN</th>
                </tr>
              </thead>
              <tbody>
                {displayAgent.map((superAg) => (
                  <>
                    <tr key={superAg.id}>
                      <td>{superAg.id}</td>
                      <td>{superAg.type}</td>
                      <td>{superAg.name}</td>
                      {/* <td>{superAg.id} </td> */}
                      <td>
                        <div className="socail-icon">
                          <a href={superAg.facebook}>
                            <img
                              src={fbImg}
                              id="fb-icon"
                              className="img-fluid icon-img"
                              alt="facebook"
                            />
                          </a>
                          {/* 
                          <a href={superAg.messenger}>
                            <img
                              id="agent_linkm"
                              src={messengerImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a> */}

                          <a href={superAg.whatsapp}>
                            <img
                              id="agent_link"
                              src={whatsImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a>
                        </div>
                      </td>
                      <td className="new_number">{superAg.number}</td>
                      <td>
                        {" "}
                        <a
                          href={superAg.complainBtn} //`/superComplain/${superAg.id}`
                          className="complain-btn"
                        >
                          COMPLAIN
                        </a>
                      </td>
                    </tr>
                    {/* <tr className="customarAgent">
                      <td colSpan={6}>
                        <p className="tabel-details">
                          সুপার এজেন্ট এর সাথে হোয়াটসঅ্যাপ ও মেসেঞ্জার ব্যতিত
                          অন্য কোনো মাধ্যমে যোগাযোগ করা যাবে না এবং যোগাযোগ করলে
                          তা গ্রহনযোগ্য হবে না ।
                        </p>
                      </td>
                    </tr> */}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAgent;
